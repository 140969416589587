export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hello i18n !!"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Title on English"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A Vue i18n next example using vue-i18n-next"])},
  "menu-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Home"])},
  "menu-onama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "menu-kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])},
  "productsiteheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welcome to Our Product site"])},
  "footer-tekst-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Footer text in German"])},
  "title-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plastics"])},
  "description-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla bla"])},
  "subtitle1-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finished Parts"])},
  "produkt-plastike-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Product Turners"])},
  "produkt-plastike-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["With one of our Murtfeldt turners, you achieve a maximum level of individuality and flexibility for the design of the production facility. We do not produce turners in series but develop and design them in close coordination with you. Check out our product portfolio LINK."])},
  "produkt-plastike-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Screws"])},
  "produkt-plastike-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We offer a wide range of customized plastic conveyor screws – whether separating screws, grouping screws, 1-in-2 strand screws, 2-in-1 strand screws, dosing and conveyor screws, turning screws, or turning grouping screws. They all transport goods steadily, quickly, and gently. Check out our product portfolio LINK."])},
  "produkt-plastike-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format Sets"])},
  "produkt-plastike-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Our format sets consist of conveyor screws, conveyor stars, and guide components made from plastics in our extensive portfolio. Conveyor screws, as described on the respective page, are precisely tailored to the respective bottle shape and proportions. The conveyor stars can be manufactured completely, in two parts, or as segments for very large systems. Guide components are produced in a project-specific form and dimension, optionally with interchangeable slide strips. Our plastic processing is optimized in all manufacturing processes. Check out our product portfolio LINK."])},
  "produkt-plastike-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tension Box"])},
  "produkt-plastike-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The international standard for chain and belt tensioning systems from the market leader 'designed & produced in Germany since 1965'. Our tensioning systems reliably maintain tension in chains and belts, thereby extending their service life. Once installed, the tensioner automatically tensions the chain or belt, making it the first choice for tensioning systems with spring force. Check out our product portfolio LINK."])},
  "produkt-plastike-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profiles"])},
  "produkt-plastike-opis-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Where friction occurs, wear and tear take place. Especially where chains run on metal guides, regular lubrication is necessary to counteract wear and noise development. Our self-lubricating chain guides, belt guides, and sliding profiles made from our proven Murtfeldt plastics 'S' and 'S1000' are not only a cost-effective alternative to metal solutions. They combine optimal running properties with high wear resistance and guarantee quiet, smooth operation. This preserves chains and belts and helps minimize maintenance intervals of systems and machinery. Check out our portfolio LINK."])},
  "subtitle2-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semi-finished Products"])},
  "poluprodukt-plastike-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Material S Group"])},
  "poluprodukt-plastike-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The category of materials PE-UHMW, PE-HMW. Since the 1950s, Material S has stood for a wide range of applications in drive and conveyor technology, proven a thousand times over. Material S is produced based on pure ultrahigh-molecular-weight low-pressure polyethylene (PE-UHMW TG 1.2) and clearly exceeds the requirements of DIN 16972. Original Material S belongs to the most successful material group among industrial plastics. In particular, Original Material S green has established itself as a brand in the plastic sector and is used wherever technically high-performance plastic is needed. Original Material S green is synonymous with good sliding properties, high wear resistance, and long service life. Check out the Material S portfolio LINK."])},
  "poluprodukt-plastike-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical Plastics"])},
  "poluprodukt-plastike-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The category of materials PA6, PA66, PA12, POM-C, PET. In the example of modified cast polyamide, produced based on PA6 C, mineral oil is incorporated during polymerization. As a result, the material is self-lubricating and retains its excellent properties throughout its service life. This significantly reduces your operating and maintenance costs. Hardly any plastic in our range has better sliding properties. In addition, Murlubric blue 'FS' is approved for food contact (EU and FDA), unlike black Murlubric. Check out our portfolio of technical materials LINK."])},
  "poluprodukt-plastike-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["High-Performance Plastics"])},
  "poluprodukt-plastike-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The category of materials PA46, PVDF, PTFE, PPS, PEEK. Murflor materials are suitable wherever outstanding resistance to chemical and hot steam exposure is required. The working range of Murflor materials starts at -200°C and can reach up to +260°C without mechanical stress. Murflor, produced based on PTFE, also has the lowest coefficient of friction of all thermoplastics. Check out the high-performance materials portfolio."])},
  "poluprodukt-plastike-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium PE Plastics"])},
  "poluprodukt-plastike-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The category of materials PE-1000, PE-500. Murdotec's premium plastics offer more than standardized PE-UHMW. They are characterized by excellent chemical resistance, very high impact resistance, and low weight. Are you looking for a versatile technical plastic? We offer you the suitable solution here. Check out the portfolio LINK."])},
  "poluprodukt-plastike-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Special Plastics PE-UHMW"])},
  "poluprodukt-plastike-opis-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Through close collaboration with customers and intensive research in our laboratories, Murdotec has continuously developed its PE plastics. This allows us today to respond individually and specifically to customer requirements. Whether wear-optimized, glide-optimized for POM or metals, heat-stabilized, antimicrobial, metal-detectable, antistatic, or electrically conductive: we offer the properties you need for your applications. Check out the portfolio LINK."])},
  "poluprodukt-plastike-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Circulating Plastics"])},
  "poluprodukt-plastike-opis-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technical PE is ideal for the reprocessing of chips and bulk goods due to its molecular structure. Variety purity and high grinding degree significantly influence the quality. Therefore, we store our materials suitable for reprocessing in variety-pure form and only mix types of the same quality. But that's not all: in our mixing lines, each batch receives a precisely defined amount of new material. With Murdotec 1000 U, you receive a reprocessed material made from the base material PE-UHMW in the highest quality."])},
  "poluprodukt-plastike-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chutes"])},
  "poluprodukt-plastike-opis-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["There are many different types of bulk goods. Sugar, salt, and grain are just as much a part of it as sand, gravel, or coal. The common element among these different raw materials is storage in silos, and the question of the suitable lining material for the silos connects these bulk goods. Check out the application possibilities LINK."])},
  "title-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metals"])},
  "description-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metal Processing. In the Metals section, we offer our customers various processing and machining options. CNC turning, CNC milling, welding, grinding, polishing, drilling, bending, punching, sheet metal processing, welding constructions, casting, and mold making."])},
  "subtitle1-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
  "produkt-metali-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNC turning"])},
  "produkt-metali-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "produkt-metali-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNC milling"])},
  "produkt-metali-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "produkt-metali-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welding"])},
  "produkt-metali-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "produkt-metali-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welded Structures"])},
  "produkt-metali-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "title-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cimmer furniture & design"])},
  "description-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this category, we promote our local talents and offer a platform for them to unfold. With great creativity, individual masterpieces are created from natural composite materials and technology. Enis Hodzic introduces himself and his works. From here, Enis continues with his presentation and shop integration."])},
  "subtitle1-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composite Materials/Nature/Technology"])},
  "produkt-kompoziti-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Composite Materials SHOP"])},
  "produkt-kompoziti-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description from the category of custom furniture manufacturing."])},
  "title-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "description-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In this category, we offer additional services such as project organization, application technical consulting, assembly service for assemblies, design, reverse engineering, prototyping, prototype development, and manufacturing."])},
  "subtitle1-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
  "produkt-usluge-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Project Organization"])},
  "produkt-usluge-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In project organization, we accompany your projects in implementation, control, and quality assurance with the agreements/specifications requested by you and according to international standards. We look forward to every new challenge."])},
  "produkt-usluge-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assembly Service for Assemblies"])},
  "produkt-usluge-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the assembly of assemblies, prefabricated components and parts are assembled into a functional end product (assembly). This includes the assembly of mechanical, electronic, pneumatic, and/or hydraulic systems. During the assembly of the individual components, our experienced assembly teams expertly assemble them into a component assembly or a partial assembly according to the construction plan. Assembly of assemblies takes place in an assembly line optimized for your components, and each assembly step is carried out professionally and economically. Comprehensive quality controls and approval for shipping are carried out after each assembly."])},
  "produkt-usluge-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Design"])},
  "produkt-usluge-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["From the idea to the completion of machines, many steps are required, ideally within the scope of a single manufacturer. We specialize in the design of various components in mechanical and plant engineering as well as in mold making. From automated systems to feed technology to sheet metal processing – we take over all necessary steps for you and work closely with you. In addition to our core competence in custom machine construction and design based on the latest CAD programs (such as SolidWorks and AutoDesk Inventor), we offer innovative solutions for your needs and ideas. We are your constant companion throughout the project and look forward to an exchange of ideas."])},
  "produkt-usluge-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reverse Engineering"])},
  "produkt-usluge-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In the field of reverse engineering of worn parts, non-existent CAD data, and non-existent technical drawings, we have years of experience reconstructing and reproducing damaged or worn parts. We look forward to your inquiries or projects."])},
  "title-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cimmer furniture & design"])},
  "description-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U ovoj kategoriji promoviramo naše lokalne talente i nudimo platformu za njihovo izražavanje. Velikom kreativnošću nastaju individualna remek-djela od prirodnih kompozitnih materijala i tehnologije. Enis Hodžić se predstavlja i predstavlja svoje radove. Odavde Enis nastavlja s prezentacijom i integracijom trgovine."])},
  "subtitle1-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompozitni Materijali/Priroda/Tehnologija"])},
  "produkt-cimmer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trgovina kompozitnim materijalima"])},
  "produkt-cimmer-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis iz kategorije proizvodnje individualnog namještaja"])},
  "o-nama-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About Us"])},
  "o-nama-opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The company F.E.S-BH D.O.O, based in Bosnia and Herzegovina and now with two locations, has succeeded in bridging the gap between Europe and the increasingly interesting Balkans. We are a system supplier in almost all industrial sectors, such as mechanical and plant engineering, conveyor technology, automotive, renewable energies, medical technology, as well as aerospace. In good time, we recognized the issues in Europe and took on the challenge of supporting the European region with our existing technical capabilities and experience. At the same time, our region, the Balkans, benefits from the creation of many jobs, at least slowing down the total migration. A win-win situation for all involved. We would be happy to demonstrate the advantages of collaboration to you personally. We are very proud to represent renowned companies such as Murtfeldt Kunststoffe and Murdotec Kunststoffe as partners in the region."])},
  "title-kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contact"])}
}