<template>

    <v-row   >
      <v-col class="divider line one-line-tamnosiva divider_slova_naslov_tamnosivi text-center">
      {{ $t("title-plastike") }}
    </v-col>
    </v-row>

    <v-row class="tekst_white1 ma-2">
          {{ $t("description-plastike") }}        
   </v-row>


    <v-row class="frontpodnaslov_white1 pa-3" align="center" justify="center">
            {{ $t("subtitle1-plastike") }}
    </v-row>


    <v-row>
      <!--v-sheet
        
        max-width="100%"
        class="mx-auto sheet_format"
      -->
        <v-sheet
        
        max-width="100%"
        class="mx-auto sheet_format"
        >

        <v-slide-group
                  v-model="plastikagotoviproduktindex"
                  class="pa-4"
                  center-active
                  show-arrows
                  >
                  <v-slide-group-item 
                      v-for="n in Object.keys(plastikagotoviprodukti) "
                      :key="plastikagotoviprodukti[n].Slug"
                      v-slot="{ isSelected, toggle }"
                      >

                          <v-card 
                              :color="isSelected ? '#0c0c0c00' : 'white'"
                              @click="toggle"
                              class="ma-2" max-width="300" min-width="300" >

                              
                              <v-img cover class="white--text align-end" height="250px" :src="plastikagotoviprodukti[n].SlikaString" lazy-src="https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png">
                              
                                  <v-card-title class="transparentna_traka" > {{  $t(plastikagotoviprodukti[n].Naziv) }}</v-card-title>
                              </v-img>

                          </v-card>

                      </v-slide-group-item>
                  
        </v-slide-group>


        <v-row justify="center" >
            <v-expand-transition>
                <v-sheet
                
                    v-if="plastikagotoviproduktindex != null"
                    :elevation="0"
                    
                    max-width="90%"
                    min-width="90%"


                    
                >
                    <v-container   class=" container" >

                      <v-row >
                        <v-col cols="12" sm="12" >
                          <v-card elevation="0"  min-width="100%">
                                    <v-card-text class="naslov_kartica text-center">
                                      {{ $t(plastikagotoviprodukti[plastikagotoviproduktindex].Naziv) }}
                                    </v-card-text>
                                </v-card>
                        </v-col>
                      </v-row>
                        <v-row class="justify-between px-3">

                            <v-col  cols="12" sm="6" >
                                <!--span class="podnaslov_white1">{{ $t(plastikagotoviprodukti[plastikagotoviproduktindex].Naziv) }}</span> <br--> 
                                <!--div class="pozadinakolona_lijevo_kartica">                   
                                  <div class="tekst_kartica" v-html= $t(plastikagotoviprodukti[plastikagotoviproduktindex].Opis) > </div>   
                                </div-->    

                                <div class="pozadinakolona_lijevo_kartica">                   
                                  <div class="tekst_kartica" > {{skratiOpis($t(plastikagotoviprodukti[plastikagotoviproduktindex].Opis), 550)}}</div>   
                                </div>                                     
                                                                

                            </v-col>
                            <v-col cols="12" sm="6"  >
                                <div class="pozadinakolona_desno_kartica">
                                    <!--img :src="plastikagotoviprodukti[plastikagotoviproduktindex].SlikaString"  style="width: 100%;height:350px;" /-->
                                    <v-img cover  :src="plastikagotoviprodukti[plastikagotoviproduktindex].SlikaString" lazy-src="https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png"></v-img>
                                  </div>
                             
                            </v-col>

                        </v-row>
                        <v-row justify="center">
                          <v-col  cols="12" sm="5">
                            <!--v-btn
                                      class="ma-2 dugmad"                                                                                               
                                      variant="outlined"
                                      @click="toggle"
                                    >
                                  
                                      Otvori produkt na stranici proizvođača
                                      <v-icon>
                                          mdi-link
                                      </v-icon>
                                  </v-btn-->    
                          </v-col>
                        </v-row>                  
                    </v-container>
                </v-sheet>
            </v-expand-transition>
        </v-row>
          


      </v-sheet>
    </v-row>
 
    <v-row class=" pa-3"><v-spacer></v-spacer></v-row>
    



    <v-row class="frontpodnaslov_white1 pa-3" align="center" justify="center">
      {{ $t("subtitle2-plastike") }}
    </v-row>


    <v-row>
      <v-sheet
        class="mx-auto sheet_format" 
        max-width="100%"
        >
        <v-slide-group
                  v-model="plastikapoluproduktindex"
                  class="pa-4"
                  center-active
                  show-arrows
                  >
                  <v-slide-group-item 
                      v-for="n in Object.keys(plastikapoluprodukti) "
                      :key="plastikapoluprodukti[n].Slug"
                      v-slot="{ isSelected, toggle }"
                      >

                          <v-card 
                              :color="isSelected ? '#0c0c0c00' : 'white'"
                              @click="toggle"
                              class="ma-2" max-width="300" min-width="300" >

                              
                              <v-img cover class="white--text align-end" height="250px" :src="plastikapoluprodukti[n].SlikaString" lazy-src="https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png">
                              
                                  <v-card-title class="transparentna_traka" > {{  $t(plastikapoluprodukti[n].Naziv) }}</v-card-title>
                              </v-img>

                          </v-card>

                      </v-slide-group-item>
                  
        </v-slide-group>


        <v-row justify="center" >
            <v-expand-transition>
                <v-sheet
                
                    v-if="plastikapoluproduktindex != null"
                    :elevation="0"
                    
                    max-width="90%"
                    min-width="90%"


                    
                >
                    <v-container   class=" container" >

                      <v-row >
                        <v-col cols="12" sm="12" >
                          <v-card elevation="0"  min-width="100%">
                                    <v-card-text class="naslov_kartica text-center">
                                      {{ $t(plastikapoluprodukti[plastikapoluproduktindex].Naziv) }}
                                    </v-card-text>
                                </v-card>
                        </v-col>
                      </v-row>
                        <v-row class="justify-between  px-3">

                            <v-col  cols="12" sm="6" >
                                <!--span class="podnaslov_white1">{{ $t(plastikapoluprodukti[plastikapoluproduktindex].Naziv) }}</span> <br--> 
                                <!--div class="pozadinakolona_lijevo_kartica">                   
                                  <div class="tekst_kartica" v-html= $t(plastikapoluprodukti[plastikapoluproduktindex].Opis) > </div>   
                                </div-->
                                <div class="pozadinakolona_lijevo_kartica">                   
                                  <div class="tekst_kartica" > {{skratiOpis($t(plastikapoluprodukti[plastikapoluproduktindex].Opis), 550)}}</div>   
                                </div>                                     
                                
                            </v-col>
                            <v-col cols="12" sm="6"  >
                                <div class="pozadinakolona_desno_kartica">
                                    <!--img :src="plastikapoluprodukti[plastikapoluproduktindex].SlikaString"  style="width: 100%;height:350px;" /-->
                                    <v-img cover  :src="plastikapoluprodukti[plastikapoluproduktindex].SlikaString" lazy-src="https://upload.wikimedia.org/wikipedia/commons/f/fc/No_picture_available.png"></v-img>
                                  </div>
                             
                            </v-col>

                        </v-row>
                        <v-row justify="center">
                          <v-col  cols="12" sm="5">
                            <!--v-btn
                                      class="ma-2 dugmad"                                                                                               
                                      variant="outlined"
                                      @click="toggle"
                                    >
                                  
                                      Otvori produkt na stranici proizvođača
                                      <v-icon>
                                          mdi-link
                                      </v-icon>
                                  </v-btn-->    
                          </v-col>
                        </v-row>                  
                    </v-container>
                </v-sheet>
            </v-expand-transition>
        </v-row>
          


      </v-sheet>
    </v-row>    

    <v-row><v-spacer></v-spacer>></v-row>

    
</template>

<script>
export default {

    data () {
      return {
        plastikagotoviproduktindex:null,
        plastikapoluproduktindex:null,
        plastikagotoviprodukti:[],
        plastikapoluprodukti:[],

       
        //snackbar
        alert:false,
        alerttext:'',
        alertsnackbar:false,
        snackbartekst:'',
        snackbartitle:'',
        multiLine: true,
        ikonasnackbar:'',
        colorsnackbar:'',          
        }

      },
      created() {
        this.loadplastikagotoviprodukti();
        this.loadplastikapoluprodukti();
    
      },

      methods:{

        skratiOpis(opis, maksimalniBrojKaraktera) {
          
          if (opis.length > maksimalniBrojKaraktera) {
            return opis.substring(0, maksimalniBrojKaraktera) ;
          } else {
            return opis;
          }
        },        
        loadplastikagotoviprodukti(){
          this.plastikagotoviprodukti=[
            {Naziv:'produkt-plastike-1', Opis:'produkt-plastike-opis-1', Slug:"1", SlikaString:"/images/proizvodi/produkt-plastike-1.jpg" },
            {Naziv:'produkt-plastike-2', Opis:'produkt-plastike-opis-2', Slug:"2", SlikaString:"/images/proizvodi/produkt-plastike-2.jpg" },
            {Naziv:'produkt-plastike-3', Opis:'produkt-plastike-opis-3', Slug:"3", SlikaString:"/images/proizvodi/produkt-plastike-3.jpg" },
            {Naziv:'produkt-plastike-4', Opis:'produkt-plastike-opis-4', Slug:"4", SlikaString:"/images/proizvodi/produkt-plastike-4.jpg" },
            {Naziv:'produkt-plastike-5', Opis:'produkt-plastike-opis-5', Slug:"5", SlikaString:"/images/proizvodi/produkt-plastike-5.jpg" }


          ]
        },

        loadplastikapoluprodukti(){
          this.plastikapoluprodukti=[
            {Naziv:'poluprodukt-plastike-1', Opis:'poluprodukt-plastike-opis-1', Slug:"1", SlikaString:"/images/proizvodi/poluprodukt-plastike-1.jpg" },
            {Naziv:'poluprodukt-plastike-2', Opis:'poluprodukt-plastike-opis-2', Slug:"2", SlikaString:"/images/proizvodi/poluprodukt-plastike-2.jpg" },
            {Naziv:'poluprodukt-plastike-3', Opis:'poluprodukt-plastike-opis-3', Slug:"3", SlikaString:"/images/proizvodi/poluprodukt-plastike-3.jpg" },
            {Naziv:'poluprodukt-plastike-4', Opis:'poluprodukt-plastike-opis-4', Slug:"4", SlikaString:"/images/proizvodi/poluprodukt-plastike-4.jpg" },
            {Naziv:'poluprodukt-plastike-5', Opis:'poluprodukt-plastike-opis-5', Slug:"5", SlikaString:"/images/proizvodi/poluprodukt-plastike-5.jpg" },
            {Naziv:'poluprodukt-plastike-6', Opis:'poluprodukt-plastike-opis-6', Slug:"6", SlikaString:"/images/proizvodi/poluprodukt-plastike-6.jpg" },
            {Naziv:'poluprodukt-plastike-7', Opis:'poluprodukt-plastike-opis-7', Slug:"7", SlikaString:"/images/proizvodi/poluprodukt-plastike-7.jpg" }

          ]
        },

        async obavijestHeading(strTekst, tip){

            switch(tip) {
            case 'info':
                this.ikonasnackbar="info";
                this.colorsnackbar="info";
                this.snackbartitle="Info"
                break;
            case 'success':
                this.ikonasnackbar="check_circle";
                this.colorsnackbar="success";
                this.snackbartitle="Obavijest"
                break;
            case 'error':
                this.ikonasnackbar="error";
                this.colorsnackbar="error";
                this.snackbartitle="Greška"
                break; 
            case 'warning':
                this.ikonasnackbar="warning";
                this.colorsnackbar="warning";
                this.snackbartitle="Upozorenje"
                break;                                        
            default:

            }

            this.snackbartekst=strTekst;
            this.alertsnackbar=true;


        },        
      }
    
}
</script>
<style >

</style>