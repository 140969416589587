export default {
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zdravo i18n !!"])},
  "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naslov na bosanskom"])},
  "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primjer Vue i18n next korištenja vue-i18n-next"])},
  "menu-home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Početna"])},
  "menu-onama": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nama"])},
  "menu-kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
  "productsiteheader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dobrodošli na našu stranicu proizvoda"])},
  "footer-tekst-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Podnožni tekst na njemačkom"])},
  "title-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plastike"])},
  "description-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "subtitle1-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gotovi dijelovi"])},
  "produkt-plastike-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Proizvodni okretači"])},
  "produkt-plastike-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S jednim od naših Murtfeldt okretača postižete maksimalnu razinu individualnosti i fleksibilnosti za dizajn proizvodnog postrojenja. Ne proizvodimo okretače serijalno, već ih razvijamo i dizajniramo u tesnoj koordinaciji s vama. Provjerite naš portfelj proizvoda LINK."])},
  "produkt-plastike-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vijci"])},
  "produkt-plastike-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nudimo širok spektar prilagođenih plastičnih transportnih vijaka - bilo da se radi o odvajanju vijaka, grupiranju vijaka, 1-u-2 strunjača, 2-u-1 strunjača, dozirnim i transportnim vijcima, okretnim vijcima ili vijcima za grupiranje. Svi oni prenose robu stabilno, brzo i nježno. Provjerite naš portfelj proizvoda LINK."])},
  "produkt-plastike-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Setovi za oblikovanje"])},
  "produkt-plastike-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Naši setovi za oblikovanje sastoje se od transportnih vijaka, transportnih zvijezda i vodiljnih komponenti izrađenih od plastike iz našeg opsežnog portfelja. Transportni vijci, kako je opisano na odgovarajućoj stranici, precizno su prilagođeni odgovarajućem obliku i proporcijama boce. Transportne zvijezde mogu biti potpuno izrađene, dvodijelne ili kao segmenti za vrlo velike sustave. Vodiljke se proizvode u obliku i dimenziji prilagođenim projektu, po želji s izmjenjivim kliznim trakama. Naša obrada plastike optimizirana je u svim proizvodnim procesima. Provjerite naš portfelj proizvoda LINK."])},
  "produkt-plastike-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kutija za napetost"])},
  "produkt-plastike-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Međunarodni standard za sustave napetosti lanaca i remenica od vodećeg proizvođača 'dizajnirano i proizvedeno u Njemačkoj od 1965. godine'. Naši sustavi za napetost pouzdano održavaju napetost u lancima i remenicama, čime se produžava njihov vijek trajanja. Jednom instaliran, napetostnik automatski napinje lanac ili remenicu, čineći ga prvom opcijom za sustave za napetost s djelovanjem opruge. Provjerite naš portfelj proizvoda LINK."])},
  "produkt-plastike-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profili"])},
  "produkt-plastike-opis-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gdje se pojavi trenje, nastaje trošenje. Posebno tamo gdje lanci trče po metalnim vodilicama, potrebno je redovito podmazivanje kako bi se suprotstavili trošenju i razvoju buke. Naši samomazivi vodiči za lance, vodiči za remene i klizni profili izrađeni od naših provjerenih Murtfeldt plastika 'S' i 'S1000' nisu samo ekonomična alternativa metalnim rješenjima. Oni kombiniraju optimalne radne karakteristike s visokom otpornošću na trošenje i jamče miran, glatan rad. To očuva lance i remene i pomaže u smanjenju intervala održavanja sustava i strojeva. Provjerite naš portfelj LINK."])},
  "subtitle2-plastike": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poluproizvodi"])},
  "poluprodukt-plastike-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Materijal S Grupa"])},
  "poluprodukt-plastike-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorija materijala PE-UHMW, PE-HMW. Od 1950-ih godina, Materijal S predstavlja širok spektar primjena u pogonu i transportnoj tehnologiji, dokazan tisućama puta. Materijal S proizvodi se na temelju čistog ultravisokomolekularnog niskotlačnog polietilena (PE-UHMW TG 1.2) i jasno premašuje zahtjeve DIN 16972. Originalni Materijal S pripada najuspješnijoj skupini materijala među industrijskim plastikama. Pogotovo originalni Materijal S zelene boje etablirao se kao brand u sektoru plastike i koristi se tamo gdje je potrebna tehnički visokoučinkovita plastika. Originalni Materijal S zelene boje sinonim je za dobre klizne karakteristike, visoku otpornost na trošenje i dugi vijek trajanja. Provjerite Materijal S portfelj LINK."])},
  "poluprodukt-plastike-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehničke Plastike"])},
  "poluprodukt-plastike-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorija materijala PA6, PA66, PA12, POM-C, PET. U primjeru modificiranog lijevanog poliamida proizvedenog na temelju PA6 C, tijekom polimerizacije mineralno ulje se ugrađuje. Time materijal postaje samomaziv i zadržava svoje izvrsne karakteristike tijekom cijelog vijeka trajanja. To značajno smanjuje vaše operativne i održavateljske troškove. Malo koji materijal u našem asortimanu ima bolje klizne karakteristike. Osim toga, Murlubric ima izvrsnu otpornost na trošenje i pogodan je čak i pri većim brzinama za visoko opterećene klizne i trošne primjene. Murlubric plave boje 'FS' suprotno od crnog Murlubric-a ima dopuštenje za upotrebu u prehrambenoj industriji (EU i FDA). Provjerite portfelj naših tehničkih materijala LINK."])},
  "poluprodukt-plastike-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visokoučinkovite Plastike"])},
  "poluprodukt-plastike-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorija materijala PA46, PVDF, PTFE, PPS, PEEK. Gdje god je potrebna izvanredna otpornost na kemijska i toplinska opterećenja, Murflor materijali su prikladni. Radni raspon Murflor materijala počinje pri -200°C i može doseći do +260°C bez mehaničkog opterećenja. Murflor, proizveden na bazi PTFE-a, ima najmanji koeficijent trenja svih termoplasta. Provjerite portfelj visokoučinkovitih materijala LINK."])},
  "poluprodukt-plastike-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premium PE Plastike"])},
  "poluprodukt-plastike-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorija materijala PE-1000, PE-500. Murdotec-ove premium plastike nude više od standardnog PE-UHMW. Odlikuju se vrlo dobrim otporom kemikalijama, visokom udarnom čvrstoćom i niskom težinom. Tražite li svestranu tehničku plastiku? Ovdje vam nudimo odgovarajuće rješenje. Provjerite portfelj LINK."])},
  "poluprodukt-plastike-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specijalne PE Plastike"])},
  "poluprodukt-plastike-opis-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kroz blisku suradnju s klijentima i intenzivno istraživanje u našim laboratorijima, Murdotec je kontinuirano razvijao svoje PE plastike. To nam danas omogućava da reagiramo individualno i ciljano na zahtjeve klijenata. Bez obzira na to želite li optimizirati trošenje, kliznost za POM ili metale, toplinsku stabilnost, antimikrobiološke, metalno otkrivajuće, antistatičke ili električno provodljive karakteristike: nudimo svojstva koja su vam potrebna za vaše primjene. Provjerite portfelj LINK."])},
  "poluprodukt-plastike-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reciklirane Plastike"])},
  "poluprodukt-plastike-opis-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tehnički PE zbog svoje molekularne strukture izvrsno je pogodan za ponovnu upotrebu strugotina i zaliha. Čistoća sorti i visoka razina mljevenja značajno utječu na kvalitetu. Zbog toga svoje materijale za ponovnu upotrebu čuvamo u čistim sortama i miješamo samo vrste iste kvalitete. Kao da to nije dovoljno: u našim mješaonicama svakoj seriji dodaje se određena količina nove sirovine. S Murdotec 1000 U dobivate pripremljen materijal iz osnovnog materijala PE-UHMW najviše kvalitete."])},
  "poluprodukt-plastike-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ispušni otvori"])},
  "poluprodukt-plastike-opis-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postoji mnogo različitih vrsta rasutih tereta. Šećer, sol i žitarice jednako su dio toga kao i pijesak, šljunak ili ugljen. Zajednički element među ovim različitim sirovinama je skladištenje u silosima, a pitanje odgovarajućeg obloženog materijala za silose povezuje ove rasute terete. Provjerite mogućnosti primjene LINK."])},
  "title-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metali"])},
  "description-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obrada metala. U odjeljku o metalima nudimo našim kupcima različite mogućnosti obrade i strojne obrade. CNC tokarenje, CNC glodanje, zavarivanje, brušenje, poliranje, bušenje, savijanje, štancanje, obrada lima, zavarene konstrukcije, lijevanje i izrada kalupa."])},
  "subtitle1-metali": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
  "produkt-metali-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNC tokarenje"])},
  "produkt-metali-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "produkt-metali-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNC frezanje"])},
  "produkt-metali-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "produkt-metali-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varenje"])},
  "produkt-metali-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "produkt-metali-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Varene konstrukcije"])},
  "produkt-metali-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
  "title-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cimmer furniture & design"])},
  "description-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U ovoj kategoriji promoviramo naše lokalne talente i nudimo platformu za njihovo izražavanje. Velikom kreativnošću nastaju individualna remek-djela od prirodnih kompozitnih materijala i tehnologije. Enis Hodžić se predstavlja i predstavlja svoje radove. Odavde Enis nastavlja s prezentacijom i integracijom trgovine."])},
  "subtitle1-kompoziti": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompozitni Materijali/Priroda/Tehnologija"])},
  "produkt-kompoziti-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trgovina kompozitnim materijalima"])},
  "produkt-kompoziti-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis iz kategorije proizvodnje individualnog namještaja"])},
  "title-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usluge"])},
  "description-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U ovom odjeljku nudimo dodatne usluge poput organizacije projekata, tehničkog savjetovanja, montaže sklopova, konstrukcije, inženjeringa u obrnutom smjeru, izrade prototipova, razvoja prototipova i proizvodnje."])},
  "subtitle1-usluge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usluge"])},
  "produkt-usluge-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organizacija projekata"])},
  "produkt-usluge-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U organizaciji projekata pratimo vaše projekte u izvedbi, upravljanju i osiguranju kvalitete s dogovorima/specifikacijama koje ste zatražili i prema međunarodnim standardima. Radujemo se svakom novom izazovu."])},
  "produkt-usluge-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montaža sklopova"])},
  "produkt-usluge-opis-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montaža sklopova obuhvaća sastavljanje unaprijed izrađenih dijelova i komponenata u funkcionalan proizvod (sklop). To uključuje montažu mehaničkih, električnih, pneumatskih i/ili hidrauličnih sustava. Pri montaži pojedinačnih komponenata naši iskusni montažni timovi ih stručno sastavljaju prema planu izrade u komponentu ili podsklop. Montaža sklopova obavlja se na montažnoj liniji optimalno prilagođenoj vašim komponentama, svaki pojedinačni montažni korak profesionalno i ekonomično izvodi. Nakon svake montaže provode se detaljne kontrole kvalitete i daje odobrenje za otpremu."])},
  "produkt-usluge-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konstrukcija"])},
  "produkt-usluge-opis-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Od ideje do završetka strojeva, potrebno je mnogo koraka, koji bi idealno trebali biti u nadležnosti jednog proizvođača. Specijalizirani smo za konstrukciju različitih dijelova u strojogradnji i inženjeringu te oblikovanju lima. Od automatiziranih sustava, tehnologije za napajanje do obrade lima - preuzimamo sve potrebne korake i surađujemo s vama. Osim naše osnovne kompetencije u izradi posebnih strojeva i konstrukciji na temelju najnovijih CAD programa (kao što su SolidWorks i AutoDesk Inventor), nudimo inovativna rješenja za vaše želje i ideje. Tijekom cijelog projekta pratimo vas i radujemo se razmjeni ideja."])},
  "produkt-usluge-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inženjering u Obrnutom Smjeru"])},
  "produkt-usluge-opis-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U području inženjeringa u obrnutom smjeru za trošne dijelove, nepostojeće CAD podatke i tehničke crteže, imamo višegodišnje iskustvo u rekonstrukciji i reprodukciji dijelova koji su oštećeni ili istrošeni. Radujemo se vašim upitima ili projektima."])},
  "title-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cimmer furniture & design"])},
  "description-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U ovoj kategoriji promoviramo naše lokalne talente i nudimo platformu za njihovo izražavanje. Velikom kreativnošću nastaju individualna remek-djela od prirodnih kompozitnih materijala i tehnologije. Enis Hodžić se predstavlja i predstavlja svoje radove. Odavde Enis nastavlja s prezentacijom i integracijom trgovine."])},
  "subtitle1-cimmer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kompozitni Materijali/Priroda/Tehnologija"])},
  "produkt-cimmer-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trgovina kompozitnim materijalima"])},
  "produkt-cimmer-opis-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opis iz kategorije proizvodnje individualnog namještaja"])},
  "o-nama-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O nama"])},
  "o-nama-opis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tvrtka F.E.S-BH D.O.O, sa sjedištem u Bosni i Hercegovini i sada s dvije lokacije, uspjela je uspostaviti most između Europe i sve interesantnijeg Balkana. Mi smo sistemski dobavljač u gotovo svim sektorima industrije, kao što su strojogradnja, transportna tehnologija, automobilska industrija, obnovljivi izvori energije, medicinska tehnologija, zrakoplovstvo i svemir. Na vrijeme smo prepoznali izazove u Europi i prihvatili ih, te svojim tehničkim mogućnostima i iskustvom podržavamo europski prostor. Istovremeno, naša regija, Balkan, profitira osnivanjem mnogo radnih mjesta, usporavajući barem malo totalno iseljavanje. Praktički situacija dobitak-za-sve-uključene. Prednosti suradnje rado ćemo predočiti, tako da ostanemo konkurentska alternativa u našem području."])},
  "title-kontakt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
}